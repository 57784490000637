import React, { useState, useEffect } from "react";
import Swiper from 'react-id-swiper';
import "./heroslider.scss";
import Cookies from 'js-cookie'
import GenericBannerContainer from "../GenericBanner/genericbannercontainer";
export default function HeroSlider(props) {
  const [themeClass, updateThemeClass] = useState("theme-light");
  const [heroSliderComponent, updateHeroSliderComponent] = useState(null);
  let _userCountryCode = "US";
  const setThemeClass = () => {
    let isDarkTheme = document.querySelector(".swiper-slide-active > .theme-dark");
    updateThemeClass(isDarkTheme ? "theme-dark" : "theme-light");
  }
  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 5500,
      disableOnInteraction: false
    },
    effect: 'fade',
    on: {
      transitionEnd: setThemeClass,
      init: setThemeClass
    }
  }
  if (props.DEVICE_VIEW.IsDesktop) {
    params.navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  const populateTargetAudienceSlides = (_userCountryCode) => {
    let slides = [];
    if (props.slides)
      props.slides.map((slide, index) => {
        if (slide.targetAudience === "All" || slide.targetAudience === _userCountryCode) {
          slides.push(
            <div key={index}>
              <GenericBannerContainer {...slide} DEVICE_VIEW={props.DEVICE_VIEW} isSlider={true}></GenericBannerContainer>
            </div>
          );
        }
        return null;
      });
    updateHeroSliderComponent(<Swiper {...params} >{slides}</Swiper>);
  }
  useEffect(() => {
    try {
      if (!heroSliderComponent) {
        let countryCodeCookieValue = Cookies.get('_userCountryCode');
        if (!countryCodeCookieValue) {
          fetch('https://ipapi.co/json/').then((response) => {
            return response.json();
          }).then(data => {
            let _userCountryCode = data.country_code === "US" ? "US" : "Non-US";
            Cookies.set('_userCountryCode', _userCountryCode, { expires: 30 });
            populateTargetAudienceSlides(_userCountryCode);
          }).catch(function (e) {
            console.log(e);
            //in case of error in location detection api call -- use US
            populateTargetAudienceSlides(_userCountryCode);
          });
        }
        else {
          //populate target audience slides with cookie value
          populateTargetAudienceSlides(countryCodeCookieValue);
        }
      }
    }
    catch (e) {
      console.log(e);
      //in case of error in location detection api call -- use US
      populateTargetAudienceSlides(_userCountryCode);
    }
  });
  if (!props.slides)
    return null;
  return (
    <div className={`${themeClass}`}>
      {heroSliderComponent}
    </div>
  );
};
